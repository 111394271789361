interface ConnatixDataProps {
  sites: {
    slug: string;
    sites: number[];
    id: string | null;
    token: string | null;
  }[];
}

export const connatixPlayerData: ConnatixDataProps = {
  sites: [
    {
      slug: 'alabama-crimson-tide',
      sites: [1, 72],
      id: 'e306ef0adbd94127a57eb22e5aadd31b',
      token: 'f46e9ae3-ae76-49b8-929d-c21c8a3de162',
    },
    {
      slug: 'auburn-tigers',
      sites: [5, 76],
      id: '237afd2393a745af9d86600f6e236826',
      token: 'e98b7d39-f1df-4243-aa87-2664b16891cb',
    },
    {
      slug: 'florida-gators',
      sites: [13, 83],
      id: '59308131df7642589671798715055ceb',
      token: '4ecc8117-ed8e-48e4-b177-0e907ece8c89',
    },
    {
      slug: 'florida-state-seminoles',
      sites: [14, 372],
      id: 'c4b3852fe6294923bd06083bcbb3c6f2',
      token: 'fe7a74b7-fbf2-4d68-9742-da3b15f6da79',
    },
    {
      slug: 'georgia-bulldogs',
      sites: [15, 74],
      id: '640caf1b11ad4faeb58788fab2031401',
      token: 'a479737c-e896-4fc3-b299-a14a98b01cd7',
    },
    {
      slug: 'kansas-state-wildcats',
      sites: [22, 374],
      id: '0a9006fd10ce49b6bb6f7473e51d257b',
      token: '6c52b877-a53c-4bec-8ed7-0924f6eef19f',
    },
    {
      slug: 'kentucky-wildcats',
      sites: [24, 23],
      // Need update
      id: 'd48921a970ee463fbdd59917b74db689',
      token: 'f9607882-2ee8-4231-ab39-60e6d345beff',
    },
    {
      slug: 'lsu-tigers',
      sites: [27, 364],
      id: 'f26e948e95274ec7bc979c1ef5b32a62',
      token: 'a9f2bcc6-8241-4f75-8b40-53882ccb91b7',
    },
    {
      slug: 'miami-hurricanes',
      sites: [29, 371],
      id: '316beae8e54c49e09b8a16d869b92219',
      token: '8c2f4f78-20da-42f9-a5aa-91b984734feb',
    },
    {
      slug: 'michigan-wolverines',
      sites: [31, 80],
      id: 'a87e8e3ca65e4a7abee7bcf9e7b1e1d5',
      token: '71f1f16f-f961-4ea1-a0ee-b30d3f538f86',
    },
    {
      slug: 'michigan-state-spartans',
      sites: [30, 382],
      id: 'e01bff44f81e41919135075d37efdbca',
      token: '154d0204-e289-4311-9390-f8476d3de9ac',
    },
    {
      slug: 'purdue-boilermakers',
      sites: [49, 377],
      id: 'a73a22b37ac04545ab87cff1ded3fd3f',
      token: 'eb20b88c-e335-47ab-90c3-0357dcf4f570',
    },
    {
      slug: 'mississippi-state-bulldogs',
      sites: [33],
      id: '2cba437b04a74a01985afefe31911c00',
      token: '29c3c5ee-2cb7-452e-9c3b-0048f5ac50c7',
    },
    {
      slug: 'nc-state-wolfpack',
      sites: [35, 82],
      id: 'a24a3f93fcce4510aaab0c35dcc82144',
      token: '719ee3c6-7770-44d1-94e7-170d0e2f1353',
    },
    {
      slug: 'nebraska-cornhuskers',
      sites: [36, 370],
      id: '5a331441a36b47d69725fb1b1c8b95a4',
      token: 'c70368af-916c-4010-8ef7-fe3893d965a0',
    },
    {
      slug: 'notre-dame-fighting-irish',
      sites: [39, 363],
      id: '2852c13dfa3e40ac85c776f69133f1ab',
      token: '3e3767d0-9bbd-4060-b8c7-ba90f353c7d9',
    },
    {
      slug: 'ohio-state-buckeyes',
      sites: [25, 40],
      id: '1750272f17d54775ae5917518b041662',
      token: '2f44bf0f-fc4b-47e6-b2f9-e3fc24e32997',
    },
    {
      slug: 'ole-miss-rebels',
      sites: [43, 362],
      id: 'b95e2ff6e6284db8b7bbb1e0eff51a53',
      token: 'df03a322-0ae6-4db6-ab89-fcf037985f2c',
    },
    {
      slug: 'oregon-ducks',
      sites: [45, 75],
      id: 'ea03505c4d69458dbc41cd60df9cc773',
      token: '9762add8-0f10-49f7-bb34-a35e70fd24d7',
    },
    {
      slug: 'penn-state-nittany-lions',
      sites: [47, 81],
      id: '62e3e727461848e3bdc4f4ee654bb78f',
      token: 'd8e57f6a-990b-4be1-9147-f82458943be3',
    },
    {
      slug: 'smu-mustangs',
      sites: [248, 365],
      id: '4e04699e922e42f9a85f6ff2fc8e72c3',
      token: 'f2511a1d-630f-4fe2-9e35-4141c805b9cd',
    },
    {
      slug: 'south-carolina-gamecocks',
      sites: [51, 366],
      // Need update
      id: null,
      token: null,
    },
    {
      slug: 'tennessee-volunteers',
      sites: [55, 373],
      id: '7ca8ba1fc37c4d67b742ac514fc44c4c',
      token: '48b14701-585c-4206-8a89-4db907ca7d70',
    },
    {
      slug: 'texas-longhorns',
      sites: [57, 73],
      id: '0b01504f10bc43349fa3a273eb9e7238',
      token: 'bbf33659-17e2-46ea-b3b5-389b7282c352',
    },
    {
      slug: 'usc-trojans',
      sites: [60, 79],
      id: '24a53bfee1b84b03b68d41ee943cf3b7',
      token: 'fec1051f-0887-43ab-b356-232bb14b2325',
    },
    {
      slug: 'on3-pro',
      sites: [397],
      id: '1f1f85a96b5b4220aadaac4282820ac2',
      token: '0eb9673f-005d-4bee-9dbb-1d99db2e8a33',
    },
  ],
};
