import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';

import styles from './Leader.module.scss';

interface IAdProps {
  id: string;
}

export const Leader = ({ id }: IAdProps) => {
  const { user } = useAuth();

  if (user?.st?.includes('premium')) {
    return null;
  }

  return <div className={styles.ad} id={id} />;
};
