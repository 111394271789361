import Script from 'next/script';

interface ArticleScriptsProps {
  data: string[];
}

export const ArticleScripts = ({ data }: ArticleScriptsProps) => {
  return (
    <>
      {data.map((src, index) => (
        <Script key={index} src={src} strategy="lazyOnload" />
      ))}
    </>
  );
};
