import { useSite } from '@on3/ui-lib/index';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import styles from './ArticleBarrier.module.scss';

export const ArticleBarrier = () => {
  const { subConfig } = useSite();
  const router = useRouter();
  const { asPath: path } = router;
  const {
    monthlyOffer,
    monthlyArticleTemplateVariantId,
    annualArticleTemplateVariantId,
    articleTemplateId,
    monthlyPromotionCode,
    annualPromotionCode,
    annualOffer,
  } = subConfig || {};
  let hasInitialized = false;

  useEffect(() => {
    if (!hasInitialized) {
      window.tp.push([
        'init',
        function () {
          // monthly
          window.tp.offer.show({
            offerId: monthlyOffer,
            templateId: articleTemplateId,
            templateVariantId: monthlyArticleTemplateVariantId,
            promoCode: monthlyPromotionCode,
            displayMode: 'inline',
            containerSelector: '#monthly',
          });
          // annual
          window.tp.offer.show({
            offerId: annualOffer,
            templateId: articleTemplateId,
            templateVariantId: annualArticleTemplateVariantId,
            promoCode: annualPromotionCode,
            displayMode: 'inline',
            containerSelector: '#annual',
          });
        },
      ]);
    }

    hasInitialized = true;
  }, []);
  useEffect(() => {
    document.body.classList.add('blocker-active');

    return () => {
      document.body.classList.remove('blocker-active');
    };
  }, []);

  return (
    <>
      <div className={styles.pianoBottom} id="pianoBottom">
        <header>
          <div>Get unlimited access today.</div>
          <h2>Pick the right plan for you.</h2>
        </header>
        <div id="annual" />
        <div id="monthly" />
        <footer>
          <span>Already a member?</span>
          <Link href={`/login?returnto=${path}`}>Login</Link>
        </footer>
      </div>
      <div className={styles.blocker} id="blocker" />
    </>
  );
};
