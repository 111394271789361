import styles from './NascarBar.module.scss';

export const NascarBar = () => {
  return (
    <div className={styles.block}>
      <img
        alt="NASCAR Logo"
        className={styles.nascarImage}
        height={20}
        src={`https://on3static.com/cdn-cgi/image/height=40,width=130/static/nascar.png`}
        width={115}
      />
    </div>
  );
};
