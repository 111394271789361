import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import { useEffect } from 'react';

import { ArticleBarrier } from './ArticleBarrier/ArticleBarrier';
import { ArticleWrapper, type IArticleWrapperProps } from './ArticleWrapper';
import { NascarBar } from './NascarBar/NascarBar';

const NASCAR_CATEGORY_KEY = 540543;

export const ArticleShell = (props: IArticleWrapperProps) => {
  const { user } = useAuth();
  const noAccess = props?.article?.isPremium && !user?.has;
  const showNascar =
    props?.article?.primaryCategory?.key === NASCAR_CATEGORY_KEY;

  useEffect(() => {
    if (!noAccess) {
      document.body.classList.remove('blocker-active');
    }
  }, [noAccess]);

  return (
    <PageWrapper {...props} hideAds={noAccess}>
      {showNascar ? <NascarBar /> : null}
      <ArticleWrapper {...props} key={props?.article?.key} />
      {noAccess && <ArticleBarrier />}
    </PageWrapper>
  );
};
