import { Comment, NotificationImportant } from '@mui/icons-material';
import type {
  HeadlineResponse,
  TrendingListItemResponseTrendingListResponseRelatedPagedData,
} from '@on3/api';
import { On3IconPremiumPlus } from '@on3/icons';
import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { toFriendlyDate } from '@on3/ui-lib/src/utils/date';
import { cmsUrl, databaseUrl } from '@on3/ui-lib/utils/api';
import clsx from 'clsx';
import { ConnatixPlayer } from 'components/Ads/ConnatixPlayer/ConnatixPlayer';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';
import { ResizedImage } from 'components/Image/ResizedImage';
import { PianoBarrier } from 'components/Promos/PianoBarrier';
import { On3HeaderGrey } from 'components/Svg/On3HeaderGrey';
import { Twitter } from 'components/Svg/Twitter';
import { TopHeadlines } from 'components/TopHeadlines';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import Head from 'next/head';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Fragment, useMemo, useState } from 'react';

import styles from './Article.module.scss';
import { ArticleScripts } from './ArticleScripts';
import { ShareIcons } from './ShareIcons/ShareIcons';

interface IArticleProps extends HeadlineResponse {
  articleNumber: number;
  articleKey?: number | null;
  loaded?: boolean;
  lastArticle: boolean;
  trendingList: TrendingListItemResponseTrendingListResponseRelatedPagedData | null;
}

export const Article = ({
  slug,
  title,
  body,
  featuredImage,
  author,
  postDate,
  fullUrl,
  articleNumber,
  articleKey,
  isPremium,
  primaryCategory,
  loaded,
  hasYouTubeVideo,
  lastArticle,
  trendingList,
}: IArticleProps) => {
  const { currentSite } = useSite();
  const primaryColor = currentSite?.linkColor || currentSite?.primaryColor;
  const { user, isDeveloper, hasArticleEditAccess, canEditArticle } = useAuth();
  const isFeed = currentSite?.template === 'Feed';
  const isFanSite = currentSite?.isTeam;
  const isKsr = currentSite?.key === 24;
  const isFirstArticle = articleNumber === 0;
  const hasAccess = useMemo(
    () => !isPremium || (isPremium && (user?.has || hasArticleEditAccess())),
    [isPremium, user?.has, hasArticleEditAccess],
  );
  const pathname = usePathname();
  const showFeaturedImage = (featuredImage && !hasYouTubeVideo) || !hasAccess;

  let totalIncUnits = (typeof window !== 'undefined' && window?.incUnits) || 0;
  const [runningTotal, setRunningTotal] = useState(0);
  const scripts: string[] = [];

  const options: HTMLReactParserOptions = {
    replace(domNode) {
      if ('attribs' in domNode && domNode.type === 'script') {
        if (!scripts.includes(domNode.attribs.src)) {
          scripts.push(domNode.attribs.src);
        }
      }
    },
  };

  const parsedMarkup = parse(body || '', options);

  /** Get number of p tags in article to know where to place ads */
  const pTagsCount =
    (Array.isArray(parsedMarkup) &&
      parsedMarkup.filter(
        (item?: JSX.Element) =>
          item?.type === 'p' && item?.props?.children?.toString().length > 100,
      ).length) ||
    0;

  const suggestedContent = pTagsCount > 3 && Math.round(pTagsCount * 0.6);

  /** Function that inserts ad depending on pTagsCount */
  const markupWithAds = useMemo(() => {
    let pTags = 0;
    let runningAds = 0;
    /** First ad will always be placed after 3rd paragraph */
    const adPlacement = [3];

    // these are separated incase we want to adjust individually
    const inArticleVideoAdPlacement: number | null = 2;

    const paragraphSkip = isFanSite ? 4 : 2;
    const offset = isFanSite ? 7 : 5;

    for (let i = offset; i <= pTagsCount; i += paragraphSkip) {
      adPlacement.push(i);
    }

    const newMarkup =
      Array.isArray(parsedMarkup) &&
      parsedMarkup.map((item) => {
        // Some writers have 1 word paragraphs. This was added to prevent ads from showing too often.
        const isEligibleNode =
          item.type === 'p' &&
          (item?.props?.children?.toString().length || 0) >= 100;

        if (isEligibleNode) {
          pTags = pTags + 1;
          let adID = '';
          let mobileAdID = '';
          let hasConnatix = false;
          let hasSuggested = false;
          const hasDisplayAd = adPlacement.includes(pTags);

          if (hasDisplayAd) {
            runningAds++;
            // first ad in first article needs to be mobile_mpu_top
            if (isFirstArticle && adPlacement.indexOf(pTags) === 0) {
              adID = 'mpu_inc-1';
              mobileAdID = 'mobile_mpu_top';
            } else {
              // all others will be mobile_mpu_inc-#
              totalIncUnits++;
              adID = `mpu_inc-${totalIncUnits + 1}`;
              mobileAdID = `mobile_mpu_inc-${totalIncUnits}`;
            }
          }

          if (pTags === inArticleVideoAdPlacement) {
            hasConnatix = true;
          }

          if (pTags === suggestedContent) {
            hasSuggested = true;
          }

          return (
            <Fragment key={`${slug}-${pTags}`}>
              {item}
              {hasConnatix && <ConnatixPlayer />}
              {hasDisplayAd && (
                <>
                  <MobileMPU id={mobileAdID} incontent={true} />
                  <MPU id={adID} incontent={true} />
                </>
              )}
              {hasSuggested && trendingList && (
                <div className={styles.trending}>
                  <TopHeadlines data={trendingList} />
                </div>
              )}
            </Fragment>
          );
        }

        return item;
      });

    setRunningTotal(runningAds);

    return newMarkup;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editLink = `${cmsUrl}/wp-admin/post.php?post=${articleKey}&action=edit`;
  const notificationLink = `${databaseUrl}/developers/notification-details/${articleKey}/`;

  if (!loaded) {
    return null;
  }

  return (
    <div
      className={styles.articleWrapper}
      data-article
      data-articlekey={articleKey}
      data-totalads={runningTotal}
    >
      {isKsr && (
        <Head>
          <meta content="Kentucky Wildcats" property="og:category" />
        </Head>
      )}
      <style>
        {`
          :root {
            --primary-color: ${primaryColor};
          }
        `}
      </style>

      <ArticleScripts data={scripts} key={pathname} />

      <article
        className={clsx(styles.article, {
          [styles.scrollArticle]: !lastArticle,
        })}
        id={`article-${articleNumber + 1}`}
      >
        {(canEditArticle(currentSite?.key || 0) || isDeveloper()) && (
          <div className={styles.adminBlock}>
            {canEditArticle(currentSite?.key || 0) && (
              <a className={styles.editlink} href={editLink}>
                Edit Article
              </a>
            )}{' '}
            {isDeveloper() && (
              <a className={styles.editlink} href={notificationLink}>
                <NotificationImportant />
              </a>
            )}
          </div>
        )}
        <header className={styles.header}>
          <Text className={styles.heading} variant="h1">
            {title}
          </Text>

          <div className={styles.topauthorblock}>
            {author?.profilePictureResponse && (
              <ResizedImage
                className={styles.avatar}
                {...author?.profilePictureResponse}
                fit="cover"
                gravity="0.5x0.5"
                height={40}
                loading={isFirstArticle ? 'eager' : 'lazy'}
                quality={90}
                width={40}
              />
            )}
            <span className={styles.writtenby}>by:</span>
            <span>
              <TextLink href={`/user/${author?.niceName}`}>
                {author?.name || author?.niceName}
              </TextLink>
            </span>
            <span>•</span>
            <span>{toFriendlyDate(postDate)}</span>

            {author?.twitterHandle && (
              <>
                <span className={styles.xBlock}>•</span>
                <span className={styles.xBlock}>
                  <a
                    className={styles.twitter}
                    href={`https://twitter.com/${author?.twitterHandle}`}
                  >
                    <Twitter className={styles.svg} />
                    <Text color="primary">{author?.twitterHandle}</Text>
                  </a>
                </span>
              </>
            )}
            {isPremium && hasArticleEditAccess() && (
              <On3IconPremiumPlus
                className={styles.on3Icon}
                height={14}
                width={40}
              />
            )}
          </div>
          <div className={styles.shareBlock}>
            <ShareIcons
              fullUrl={fullUrl ?? ''}
              primaryCategoryKey={primaryCategory?.key}
              slug={slug}
              title={title ?? ''}
            />
          </div>
        </header>
        <div>
          <div>
            {showFeaturedImage && (
              <figure className={styles.figure}>
                <ResizedImage
                  className={styles.featuredimage}
                  {...featuredImage}
                  fit="cover"
                  gravity="0.5x0.5"
                  height={417}
                  loading={isFirstArticle ? 'eager' : 'lazy'}
                  quality={90}
                  width={795}
                />

                {featuredImage?.caption && (
                  <figcaption className={styles.caption}>
                    {featuredImage?.caption}
                  </figcaption>
                )}
              </figure>
            )}
            <div
              className={clsx(
                { [styles.restrict]: !hasAccess },
                styles.innerstyles,
              )}
            >
              {hasAccess ? markupWithAds : <p>{body}</p>}
            </div>

            {!hasAccess && !isFirstArticle && <PianoBarrier />}

            {isFeed && (
              <>
                <MPU id={`mpu_bottom-${articleNumber + 1}`} />
                <MobileMPU id={`mobile_mpu_bottom-${articleNumber + 1}`} />
                <div className={styles.commentBlock}>
                  <h3 className={styles.commentHeader}>Discuss This Article</h3>
                  <div className={styles.commentInnerBlock}>
                    <p className={styles.movedText}>Comments have moved.</p>
                    <p className={styles.conversationText}>
                      Join the conversation and talk about this article and all
                      things Kentucky Sports in the new{' '}
                      <Link href="/boards/forums/ksboard.172/" target="_blank">
                        KSR Message Board.
                      </Link>
                    </p>
                    <Link
                      className={styles.boardButton}
                      href="/boards/forums/ksboard.172/"
                      target="_blank"
                    >
                      <Comment />
                      <Text className={styles.innerLink}>KSBoard</Text>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {!lastArticle && (
          <a className={styles.seperatorbar} href="https://www.on3.com/">
            <On3HeaderGrey />
          </a>
        )}
      </article>
    </div>
  );
};
